import { buildApiRequest } from '@core/api';

import {
  // eslint-disable-next-line import/named
  getterNames,
  API_ACCOUNT_PROPERTIES,
  API_ACCOUNT_PROPERTY,
  API_ACCOUNT_PROPERTY_UPDATE,
  API_GET_ACCOUNT_UNASSIGNED_TEAM_MEMBERS,
  API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER,
  API_PUT_ACCOUNT_PROPERTY_BULK_UPDATE,
  API_POST_ACCOUNT_CREATE_CORPORATE_SITE,
} from './types';

import axios from '@/utils/axios';

import {
  accountProperties as apiAccountProperties,
  accountPropertiesDetails as apiAccountPropertiesDetails,
  accountPropertiesUpdate as apiAccountPropertiesUpdate,
  accountUnassignedTeamMembers as apiAccountUnassignedTeamMembers,
  accountPropertiesAssignTeamMember as apiAccountPropertiesAssignTeamMember,
  accountPropertyBulkUpdate as apiAccountPropertyBulkUpdate,
  accountCreateCorporateSite as apiAccountCreateCorporateSite,
} from '@/api/routes/properties';

const state = {
  isFetching: false,
  isFetchingProperty: false,
  error: null,
  properties: [],
  property: {},
};

const getters = {
  [getterNames.PROPS_GET_ACCOUNT_UNASSIGNED_TEAM_MEMBERS_FETCHING]:
    state => state?.get_account_unassigned_team_members?.isFetching,
};

const mutations = {
  [API_ACCOUNT_PROPERTIES.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_ACCOUNT_PROPERTIES.SUCCESS](state, data) {
    state.properties = data;
    state.error = null;
    state.isFetching = false;
  },
  [API_ACCOUNT_PROPERTIES.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },

  [API_ACCOUNT_PROPERTY.REQUEST](state) {
    state.error = null;
    state.isFetchingProperty = true;
  },
  [API_ACCOUNT_PROPERTY.SUCCESS](state, property) {
    state.property = property;
    state.error = null;
    state.isFetchingProperty = false;
  },
  [API_ACCOUNT_PROPERTY.ERROR](state, error) {
    state.error = error;
    state.isFetchingProperty = false;
  },

  [API_ACCOUNT_PROPERTY_UPDATE.REQUEST](state) {
    state.error = null;
    state.isFetchingProperty = true;
  },
  [API_ACCOUNT_PROPERTY_UPDATE.SUCCESS](state) {
    state.error = null;
    state.isFetchingProperty = false;
  },
  [API_ACCOUNT_PROPERTY_UPDATE.ERROR](state, error) {
    state.error = error;
    state.isFetchingProperty = false;
  },

  [API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER.REQUEST](state) {
    state.error = null;
    state.unassignedTeamMembers = [];
  },
  [API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER.SUCCESS](state) {
    state.error = null;
  },
  [API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER.ERROR](state, error) {
    state.error = error;
  },
};

const actions = {
  async [API_ACCOUNT_PROPERTIES.REQUEST](
    { commit },
    {
      account, resultsPerPage = 10, pageNumber = 1, filterString = '',
    },
  ) {
    try {
      commit(API_ACCOUNT_PROPERTIES.REQUEST);

      const url = apiAccountProperties.replace(':account', account.id);

      const { data } = await axios.get(
        `${url}?per_page=${resultsPerPage}&page_num=${pageNumber}&filters[0][field]=name&filters[0][operator]=like&filters[0][value]=${filterString}`,
      );

      if (!data) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_PROPERTIES.SUCCESS, data);
    } catch (error) {
      commit(API_ACCOUNT_PROPERTIES.ERROR, error);
    }
  },
  async [API_ACCOUNT_PROPERTY.REQUEST]({ commit }, { account, property }) {
    try {
      commit(API_ACCOUNT_PROPERTY.REQUEST);

      const url = apiAccountPropertiesDetails
        .replace(':id', account.id)
        .replace(':property', property);
      const { data } = await axios.get(url);

      if (!data) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_PROPERTY.SUCCESS, data);
    } catch (error) {
      commit(API_ACCOUNT_PROPERTY.ERROR, error);
    }
  },
  async [API_ACCOUNT_PROPERTY_UPDATE.REQUEST](
    { commit },
    { account, propertyData },
  ) {
    const result = {
      result: null,
      error: null,
    };

    try {
      commit(API_ACCOUNT_PROPERTY_UPDATE.REQUEST);

      const {
        id: propertyId,
        name,
        site,
        timezone,
        address,
        brand,
        logo,
        landing,
        rooms,
        stars,
        type,
        languages,
        leftToRight,
        currency,
        currencyFormat,
        dateFormat,
        bookingMultiplier,
        widgetAnalytics,
      } = propertyData;

      const nameUpdateUrl = apiAccountPropertiesUpdate
        .replace(':id', account.id)
        .replace(':property', propertyId);

      result.result = await axios.put(nameUpdateUrl, {
        name,
        stars,
        type,
        site,
        timezone,
        languages,
        currency,
        rooms: (rooms === 0) ? null : rooms,
        currency_format: currencyFormat,
        dates_format: dateFormat,
        brand_id: brand,
        logo_64: logo,
        landing,
        left_to_right: !leftToRight,
        latitude: address.latitude,
        longitude: address.longitude,
        street: address.street,
        city: address.city,
        country: address.country,
        google_place_id: address.google_place_id,
        booking_multiplier: bookingMultiplier,
        widget_analytics: widgetAnalytics,
      });

      commit(API_ACCOUNT_PROPERTY_UPDATE.SUCCESS);
    } catch (error) {
      commit(API_ACCOUNT_PROPERTY_UPDATE.ERROR, error);
      result.error = error;
    }

    return result;
  },
  async [API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER.REQUEST](
    { commit },
    { account, property, teamMemberId },
  ) {
    try {
      commit(API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER.REQUEST);

      const url = apiAccountPropertiesAssignTeamMember
        .replace(':id', account.id)
        .replace(':property', property);
      await axios.post(url, {
        team_member_id: teamMemberId,
      });

      commit(API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER.SUCCESS);
    } catch (error) {
      commit(API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER.ERROR, error);
    }
  },
};

const propertiesModule = {
  state,
  getters,
  mutations,
  actions,
};

buildApiRequest(
  propertiesModule,
  API_PUT_ACCOUNT_PROPERTY_BULK_UPDATE,
  apiAccountPropertyBulkUpdate,
);

buildApiRequest(
  propertiesModule,
  API_GET_ACCOUNT_UNASSIGNED_TEAM_MEMBERS,
  apiAccountUnassignedTeamMembers,
);

buildApiRequest(
  propertiesModule,
  API_POST_ACCOUNT_CREATE_CORPORATE_SITE,
  apiAccountCreateCorporateSite,
);

export default propertiesModule;
