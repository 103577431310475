export const accountClusters = '/account-history/account/:account/cluster';
export const accountClusterDetails = '/account-history/account/:id/cluster/:cluster';
export const accountClusterProperties = '/account-history/account/:id/cluster/:cluster/property';
export const accountClusterCreate = '/property/account/:id/cluster';
export const accountClusterUpdate = '/property/account/:id/cluster/:cluster';
export const accountClusterPropertyAdd = '/property/account/:id/cluster/:cluster/add-property';
export const accountClusterDelete = '/property/account/:account/cluster/:id';
export const accountClusterPropertyDelete = '/property/account/:id/cluster/:cluster/remove-property';
export const accountClusterPropertyTransfer = '/property/account/:id/property/:property/change-brand';

export const accountBrands = '/account-history/account/:account/brand';
export const accountBrandCreate = '/property/account/:id/brand';
export const accountProperties = '/account-history/account/:id/team-member/:teamMemberId/property';
export const accountClusterPropertiesNoBrand = '/account-history/account/:id/property/no-brand';
