import { buildApiTypes } from '@core/api';

export const { actionTypes: API_ACCOUNT_CLUSTERS } = buildApiTypes('clusters/API_ACCOUNT_CLUSTERS');
export const { actionTypes: API_ACCOUNT_CLUSTER } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER');
export const { actionTypes: API_ACCOUNT_CLUSTER_CREATE } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_CREATE');
export const { actionTypes: API_ACCOUNT_CLUSTER_UPDATE } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_UPDATE');
export const { actionTypes: API_ACCOUNT_CLUSTER_REMOVE } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_REMOVE');
export const { actionTypes: API_ACCOUNT_CLUSTER_PROPERTIES } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_PROPERTIES');
export const { actionTypes: API_ACCOUNT_CLUSTER_PROPERTY_DELETE } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_PROPERTY_DELETE');
export const { actionTypes: API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER');
export const { actionTypes: API_ACCOUNT_CLUSTER_PROPERTY_ADD } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_PROPERTY_ADD');
export const { actionTypes: API_ACCOUNT_CLUSTER_MANAGE_PROPERTIES_REMOVE } = buildApiTypes('clusters/API_ACCOUNT_CLUSTER_MANAGE_PROPERTIES_REMOVE');

export const { actionTypes: API_ACCOUNT_BRANDS } = buildApiTypes('clusters/API_ACCOUNT_BRANDS');
export const { actionTypes: API_ACCOUNT_BRAND_CREATE } = buildApiTypes('clusters/API_ACCOUNT_BRAND_CREATE');
export const { actionTypes: API_GET_ACCOUNT_PROPERTIES_NOBRAND } = buildApiTypes('clusters/API_GET_ACCOUNT_PROPERTIES_NOBRAND');
export const { actionTypes: API_ACCOUNT_PROPERTIES } = buildApiTypes('clusters/API_ACCOUNT_PROPERTIES');

export const getterNames = {
  CLUSTERS_GET_ACCOUNT_PROPERTIES_NOBRAND: 'team/GET_ACCOUNT_PROPERTIES_NOBRAND_FETCHING',
};
