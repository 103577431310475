import { buildApiTypes } from '@core/api';

export const API_SIGNIN_ACCOUNT = {
  REQUEST: 'accounts/API_SIGNIN_ACCOUNT_REQUEST',
  SUCCESS: 'accounts/API_SIGNIN_ACCOUNT_SUCCESS',
  ERROR: 'accounts/API_SIGNIN_ACCOUNT_ERROR',
  POST: 'accounts/API_SIGNIN_ACCOUNT_POST',
  GET: 'accounts/API_SIGNIN_ACCOUNT_GET',
};

export const API_GET_USER_ACCOUNTS = {
  REQUEST: 'accounts/API_GET_USER_ACCOUNTS_REQUEST',
  SUCCESS: 'accounts/API_GET_USER_ACCOUNTS_SUCCESS',
  ERROR: 'accounts/API_GET_USER_ACCOUNTS_ERROR',
  POST: 'accounts/API_GET_USER_ACCOUNTS_POST',
};

export const API_LOGOUT_USER = 'auth/API_LOGOUT_USER';

export const API_SET_USER_ACCOUNT = {
  REQUEST: 'accounts/API_SET_USER_ACCOUNT_REQUEST',
  SUCCESS: 'accounts/API_SET_USER_ACCOUNT_SUCCESS',
  ERROR: 'accounts/API_SET_USER_ACCOUNT_ERROR',
  POST: 'accounts/API_SET_USER_ACCOUNT_POST',
};

export const API_USER_CHANGE_PASSWORD = {
  REQUEST: 'accounts/API_USER_CHANGE_PASSWORD_REQUEST',
  SUCCESS: 'accounts/API_USER_CHANGE_PASSWORD_SUCCESS',
  ERROR: 'accounts/API_USER_CHANGE_PASSWORD_ERROR',
  POST: 'accounts/API_USER_CHANGE_PASSWORD_POST',
};

export const API_USER_UPDATE_PROFILE = {
  REQUEST: 'accounts/API_USER_UPDATE_PROFILE_REQUEST',
  SUCCESS: 'accounts/API_USER_UPDATE_PROFILE_SUCCESS',
  ERROR: 'accounts/API_USER_UPDATE_PROFILE_ERROR',
  POST: 'accounts/API_USER_UPDATE_PROFILE_POST',
};

export const API_USER_RESET_PASSWORD = {
  REQUEST: 'accounts/API_USER_RESET_PASSWORD_REQUEST',
  SUCCESS: 'accounts/API_USER_RESET_PASSWORD_SUCCESS',
  ERROR: 'accounts/API_USER_RESET_PASSWORD_ERROR',
  POST: 'accounts/API_USER_RESET_PASSWORD_POST',
};

export const API_USER_VALIDATE_HASH = {
  REQUEST: 'accounts/API_USER_VALIDATE_HASH_REQUEST',
  SUCCESS: 'accounts/API_USER_VALIDATE_HASH_SUCCESS',
  ERROR: 'accounts/API_USER_VALIDATE_HASH_ERROR',
  POST: 'accounts/API_USER_VALIDATE_HASH_POST',
};

export const { actionTypes: API_GET_ACCOUNT_USER_EXISTS } = buildApiTypes('accounts/API_GET_ACCOUNT_USER_EXISTS');

export const { actionTypes: API_GET_USER_DETAILS } = buildApiTypes('accounts/API_GET_USER_DETAILS');

export const { actionTypes: API_GET_VALIDATE_HASH_FROM_INVITE } = buildApiTypes('accounts/API_GET_VALIDATE_HASH_FROM_INVITE');

export const { actionTypes: API_POST_USER_APPROVE } = buildApiTypes('accounts/API_POST_USER_APPROVE');

export const { actionTypes: API_GET_HELP_CENTER } = buildApiTypes('accounts/API_GET_HELP_CENTER');
