import { buildApiRequest } from '@core/api';

import {
  getterNames,
  API_GET_ACCOUNTS,
  API_GET_PARTNER_USER_PROPERTIES,
  API_POST_SET_ACCOUNT,
  SET_STATE_ACCOUNTS,
  SET_STATE_ACCOUNT,
} from './types';

import {
  accountsByUser as apiAcccountsByUser,
  accountSelect as apiAccountSelect,
  getPartnerUserProperties as apiGetPartnerUserProperties,
} from '@/api/routes/accounts';

const accountsModule = {
  state: {
    isFetching: false,
    accounts: {},
    account: {},
  },
  getters: {
    [getterNames.ACCOUNT_GET_PARTNER_USER_PROPERTIES_FETCHING]:
    state => state.get_partner_user_properties?.isFetching,
  },
  mutations: {
    [SET_STATE_ACCOUNTS]: (state, accounts) => {
      state.accounts = accounts || [];
    },
    [SET_STATE_ACCOUNT]: (state, account) => {
      state.account = account || {};
    },
  },
  actions: {},
};

buildApiRequest(
  accountsModule,
  API_GET_ACCOUNTS,
  apiAcccountsByUser,
);

buildApiRequest(
  accountsModule,
  API_POST_SET_ACCOUNT,
  apiAccountSelect,
);

buildApiRequest(
  accountsModule,
  API_GET_PARTNER_USER_PROPERTIES,
  apiGetPartnerUserProperties,
);

accountsModule.mutations = {
  ...accountsModule.mutations,
  [API_GET_ACCOUNTS.SUCCESS]: (state, payload) => {
    state.accounts = payload?.data.filter((item) => item.user.active_properties_count > 0) || [];
    state.get_accounts = payload;
  },
};

export default accountsModule;
