import loadI18n from '@core/plugins/i18n';

import { i18nConfig } from '@/config';
import projectLocales from '@/locales';

export const i18n = loadI18n({
  ...i18nConfig,
  messages: projectLocales,
  globalInjection: true, // Injects $t in all the app templates
  legacy: false,
});
