import { buildApiRequest } from '@core/api';

import {
  API_GET_ACCOUNT_BILLING_SUBSCRIPTIONS,
  API_GET_ACCOUNT_BILLING_INVOICES,
} from './types';

import {
  accountBillingSubscriptions as apiAccountBillingSubscriptions,
  accountBillingInvoices as apiAccountBillingInvoices,
} from '@/api/routes/billing';

const state = {
  isFetching: false,
  error: null,
  billingSubscriptionsList: {},
  billingInvoicesList: {},
};

const getters = {};

const mutations = {
  [API_GET_ACCOUNT_BILLING_SUBSCRIPTIONS.REQUEST]() {
    state.error = null;
    state.isFetching = true;
  },
  [API_GET_ACCOUNT_BILLING_SUBSCRIPTIONS.SUCCESS](billingList) {
    state.billingSubscriptionsList = billingList;
    state.isFetching = false;
  },
  [API_GET_ACCOUNT_BILLING_SUBSCRIPTIONS.ERROR](error) {
    state.error = error;
    state.isFetching = false;
  },
  [API_GET_ACCOUNT_BILLING_INVOICES.REQUEST]() {
    state.error = null;
    state.isFetching = true;
  },
  [API_GET_ACCOUNT_BILLING_INVOICES.SUCCESS](invoicesList) {
    state.billingInvoicesList = invoicesList;
    state.isFetching = false;
  },
  [API_GET_ACCOUNT_BILLING_INVOICES.ERROR](error) {
    state.error = error;
    state.isFetching = false;
  },
};

const actions = {};

const billingModule = {
  state,
  getters,
  mutations,
  actions,
};

buildApiRequest(
  billingModule,
  API_GET_ACCOUNT_BILLING_SUBSCRIPTIONS,
  apiAccountBillingSubscriptions,
);

buildApiRequest(
  billingModule,
  API_GET_ACCOUNT_BILLING_INVOICES,
  apiAccountBillingInvoices,
);

export default billingModule;
