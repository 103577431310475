import { buildApiRequest } from '@core/api';

import {
  // eslint-disable-next-line import/named
  getterNames,
  API_GET_NOTIFICATIONS,
  API_POST_NOTIFICATION,
  API_PUT_NOTIFICATION,
  API_DELETE_NOTIFICATION,
  API_PUT_STATUS_NOTIFICATION,
} from './types';

import {
  notificationsAdd as apiNotificationsAdd,
  listOfNotifications as apiListOfNotifications,
  notificationsUpdate as apiNotificationsUpdate,
  notificationsDelete as apiNotificationsDelete,
  notificationsChangeStatus as apiNotificationsChangeStatus,
} from '@/api/routes/notifications';

const state = {
};
const getters = {
  [getterNames.NOTIFICATIONS_GET_NOTIFICATIONS]:
    state => state.get_notifications?.response?.data?.data,
  [getterNames.NOTIFICATIONS_GET_NOTIFICATIONS_FETCHING]:
    state => state.get_notifications?.isFetching,
  [getterNames.NOTIFICATIONS_POST_NOTIFICATION_FETCHING]:
    state => state.post_notification?.isFetching,
  [getterNames.NOTIFICATIONS_PUT_NOTIFICATION_FETCHING]:
    state => state.put_notification?.isFetching,
  [getterNames.NOTIFICATIONS_DELETE_NOTIFICATION_FETCHING]:
    state => state.delete_notification?.isFetching,
};
const mutations = {};
const actions = {};

const notificationsModule = {
  state,
  getters,
  mutations,
  actions,
};

buildApiRequest(
  notificationsModule,
  API_GET_NOTIFICATIONS,
  apiListOfNotifications,
);

buildApiRequest(
  notificationsModule,
  API_POST_NOTIFICATION,
  apiNotificationsAdd,
);

buildApiRequest(
  notificationsModule,
  API_DELETE_NOTIFICATION,
  apiNotificationsDelete,
);

buildApiRequest(
  notificationsModule,
  API_PUT_NOTIFICATION,
  apiNotificationsUpdate,
);

buildApiRequest(
  notificationsModule,
  API_PUT_STATUS_NOTIFICATION,
  apiNotificationsChangeStatus,
);

export default notificationsModule;
