import { get } from 'lodash';
import { useI18n } from 'vue-i18n';

const { isArray } = Array;

export const useErrors = () => {
  const { t, te } = useI18n();

  const e = (key, fallback) => {
    const message = !te(key) && fallback ? fallback : t(key);
    return message;
  };

  const getResponseErrors = (errorResult) => {
    const errors = get(errorResult, 'response.data.error_messages');
    if (errors) {
      return Object.keys(errors).map(key => e(key, errors[key]));
    }

    if (get(errorResult, 'response.status') === 401) return '';

    return e(errorResult.message, e('domain_error'));
  };

  const showResponseErrors = (errorResponse, callback) => {
    const errors = getResponseErrors(errorResponse);
    if (!errors) return;

    if (isArray(errors)) {
      errors.forEach(error => callback(error || []));
    } else {
      callback(errors || '');
    }
  };

  return { e, getResponseErrors, showResponseErrors };
};
