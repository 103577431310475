import { buildApiTypes } from '@core/api';

export const { actionTypes: API_ACCOUNT_PROPERTIES } = buildApiTypes('properties/API_ACCOUNT_PROPERTIES');
export const { actionTypes: API_ACCOUNT_PROPERTY } = buildApiTypes('properties/API_ACCOUNT_PROPERTY');
export const { actionTypes: API_ACCOUNT_PROPERTY_UPDATE } = buildApiTypes('properties/API_ACCOUNT_PROPERTY_UPDATE');
export const { actionTypes: API_GET_ACCOUNT_UNASSIGNED_TEAM_MEMBERS } = buildApiTypes('properties/API_GET_ACCOUNT_UNASSIGNED_TEAM_MEMBERS');
export const { actionTypes: API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER } = buildApiTypes('properties/API_ACCOUNT_PROPERTY_ASSIGN_TEAM_MEMBER');
export const { actionTypes: API_POST_ACCOUNT_PROPERTY_LOGO } = buildApiTypes('properties/API_POST_ACCOUNT_PROPERTY_LOGO');
export const { actionTypes: API_DELETE_ACCOUNT_PROPERTY_LOGO } = buildApiTypes('properties/API_DELETE_ACCOUNT_PROPERTY_LOGO');
export const { actionTypes: API_PUT_ACCOUNT_PROPERTY_BULK_UPDATE } = buildApiTypes('properties/API_PUT_ACCOUNT_PROPERTY_BULK_UPDATE');
export const { actionTypes: API_POST_ACCOUNT_CREATE_CORPORATE_SITE } = buildApiTypes('properties/API_POST_CREATE_CORPORATE_SITE');

export const getterNames = {
  PROPS_GET_ACCOUNT_UNASSIGNED_TEAM_MEMBERS_FETCHING: 'properties/GET_SINGLE_PROPERTY_FETCHING',
};
