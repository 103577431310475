import {
  computed,
  reactive,
  ComputedRef,
  UnwrapNestedRefs,
} from 'vue';

import { store } from '@/store';
import { useAuthService } from '@/modules/Auth/services/AuthService';
import { Account } from '@/modules/Accounts/types';
import type { Property } from '@/modules/Properties/types';

import {
  getterNames,
  API_GET_ACCOUNTS,
  API_GET_PARTNER_USER_PROPERTIES,
  API_POST_SET_ACCOUNT,
  SET_STATE_ACCOUNT,
  SET_STATE_ACCOUNTS,
} from '../store/types';
import { adminAccountTypes } from '@/config';

type PartnerPropertiesResponse = {
  data: Array<Property>,
}

interface AccountsStore {
  account: ComputedRef<Account>;
  accounts: ComputedRef<Account[]>;
  isFetching: ComputedRef<boolean>;
  isOwnerOrAdmin: ComputedRef<boolean>;
  userHasMultipleAccounts: ComputedRef<number>;
  getUserAccounts: () => void;
  getPropertiesByPartner: (partnerId: string, type: string) => Promise<PartnerPropertiesResponse>;
  selectUserAccount: (account: Account) => Promise<void>;
  selectDefaultAccount: () => Promise<void>;
  isValidAccountSelected: ComputedRef<boolean>;
  isFetchingProperties: ComputedRef<boolean>;
}

export function useAccountsService(): UnwrapNestedRefs<AccountsStore> {
  const authService = useAuthService();
  const isFetching = computed(() => (
    !!store.state.accounts.get_accounts.isFetching
    || !!store.state.accounts.post_set_account.isFetching
  ));
  const accounts = computed(() => (store.state?.accounts?.accounts || []) as Account[]);
  const account = computed(() => (store.state?.accounts?.account || {}) as Account);
  const isOwnerOrAdmin = computed(() => (
    account.value
    && adminAccountTypes.includes(account.value.user?.team_member?.role as string)
  ));
  const userHasMultipleAccounts = computed(() => (accounts.value?.length || 0));
  const isValidAccountSelected = computed(() => (
    !!account.value?.id
    && !!account.value.user?.active_properties_count
  ));
  const isFetchingProperties = computed(
    () => store.getters[getterNames.ACCOUNT_GET_PARTNER_USER_PROPERTIES_FETCHING],
  );

  async function getUserAccounts() {
    const { data } = await store.dispatch(
      API_GET_ACCOUNTS.REQUEST,
      {
        params: {
          id: authService.user.id,
        },
      },
    );

    const accountsWithActiveProperties = data
      .filter((item: Account) => item.user.active_properties_count > 0);

    store.commit(SET_STATE_ACCOUNTS, accountsWithActiveProperties);
  }

  async function selectUserAccount(selectedAccount: Account) {
    await store.dispatch(
      API_POST_SET_ACCOUNT.REQUEST,
      {
        data: {
          account_id: selectedAccount.id,
        },
      },
    );

    store.commit(SET_STATE_ACCOUNT, selectedAccount);
  }

  async function selectDefaultAccount() {
    await getUserAccounts();

    if (accounts.value.length === 1) {
      await selectUserAccount(accounts.value[0]);
      return;
    }

    const defaultAccount = accounts.value.find(item => item.default);
    if (defaultAccount) {
      await selectUserAccount(defaultAccount);
    }
  }

  async function getPropertiesByPartner(partnerId: string, type: string) {
    const res = await store.dispatch(
      API_GET_PARTNER_USER_PROPERTIES.REQUEST, {
        params: {
          id: account.value.id,
          partnerId,
        },
        query: {
          per_page: 1000, // ? Hardcoded trying to fetch all records in a single call
          page_num: 1,
          'filters[0][field]': 'site_type',
          'filters[0][operator]': 'eq',
          'filters[0][value]': type || 'hotel',
        },
      },
    );
    if (res.error) {
      throw new Error(res.error);
    }
    return res.data as PartnerPropertiesResponse;
  }

  return reactive({
    account,
    accounts,
    isFetching,
    isFetchingProperties,
    isOwnerOrAdmin,
    getUserAccounts,
    getPropertiesByPartner,
    selectUserAccount,
    selectDefaultAccount,
    isValidAccountSelected,
    userHasMultipleAccounts,
  });
}
