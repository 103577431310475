import { useHttpClient } from '@core/utils';

import { apiURL } from '@/config';

const axios = useHttpClient({
  excludedUrls: ['/auth/login'],
  global: {
    baseURL: apiURL.base,
  },
});

export default axios;
