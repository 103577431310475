import { useAuthService } from '@/modules/Auth/services/AuthService';

const AuthService = useAuthService();

const routes = [
  {
    path: '/billing',
    name: 'billing',
    component: () => import(
      /* webpackChunkName: "BillingModule" */
      '@/modules/Billing/BillingModule.vue'
    ),
    meta: {
      mainClasses: 'App-main__withall',
      hideAside: false,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      accountSettingsPage: true,
      requiresOwnerOrAdmin: true,
      requiresValidUser: AuthService.isValidUser,
    },
  },
];

export default routes;
