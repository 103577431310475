import { createStore, Store } from 'vuex';

import loadStore from '@core/store';

import ui from './UI';
import ProductsStore from '@/modules/Products/store';
import AuthStore from '@/modules/Auth/store';
import AccountsStore from '@/modules/Accounts/store';
import PropertiesStore from '@/modules/Properties/store';
import ClustersStore from '@/modules/Clusters/store';
import TeamsStore from '@/modules/Team/store';
import SettingsStore from '@/modules/Settings/store';
import PreferencesStore from '@/modules/Preferences/store';
import NotificationsStore from '@/modules/Notifications/store';
import BillingStore from '@/modules/Billing/store';

const projectStore = {
  state() {
    return {};
  },
  mutations: {},
  actions: {},
  modules: {
    auth: AuthStore,
    accounts: AccountsStore,
    properties: PropertiesStore,
    products: ProductsStore,
    clusters: ClustersStore,
    team: TeamsStore,
    settings: SettingsStore,
    preferences: PreferencesStore,
    notifications: NotificationsStore,
    billing: BillingStore,
    ui,
  },
};

export const store: Store<any> = loadStore(projectStore) || createStore({});
