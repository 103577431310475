import AppModule from '@/modules/AppModule.vue';
import AuthModuleRoutes from '@/modules/Auth/routes';
import AccountsModule from '@/modules/Accounts/routes';
import ProductsModuleRoutes from '@/modules/Products/routes';
import PreferencesModuleRoutes from '@/modules/Preferences/routes';
import SettingsModuleRoutes from '@/modules/Settings/routes';
import PropertiesModuleRoutes from '@/modules/Properties/routes';
import ClustersModuleRoutes from '@/modules/Clusters/routes';
import TeamModuleRoutes from '@/modules/Team/routes';
import NotificationsModuleRoutes from '@/modules/Notifications/routes';
import CorporateModuleRoutes from '@/modules/Corporate/routes';
import BillingModuleRoutes from '@/modules/Billing/routes';

const routes = [
  ...AuthModuleRoutes,
  ...AccountsModule,
  ...ProductsModuleRoutes,
  ...PreferencesModuleRoutes,
  ...SettingsModuleRoutes,
  ...PropertiesModuleRoutes,
  ...ClustersModuleRoutes,
  ...TeamModuleRoutes,
  ...NotificationsModuleRoutes,
  ...CorporateModuleRoutes,
  ...BillingModuleRoutes,
  {
    path: '/loginas/:loginAsUserId/:powerLevels?',
    component: AppModule,
    meta: {
      hideHeader: true,
      hideAside: true,
      hideFooter: true,
      requiresAuth: true,
    },
  },
  {
    path: '/redirect/:redirectTo/:redirectPath?',
    component: AppModule,
    meta: {
      hideHeader: true,
      hideAside: true,
      hideFooter: true,
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/products',
  },
];

export default routes;
