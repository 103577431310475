import { useAuthService } from '@/modules/Auth/services/AuthService';

const AuthService = useAuthService();

const routes = [
  {
    path: '/team',
    name: 'Team',
    component: () => import(
      /* webpackChunkName: "TeamModule" */
      '@/modules/Team/TeamModule.vue'
    ),
    meta: {
      mainClasses: 'App-main__withall',
      hideAside: false,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      accountSettingsPage: true,
      requiresOwnerOrAdmin: true,
      requiresValidUser: AuthService.isValidUser,
    },
    children: [
      {
        path: '',
        name: 'team-list',
        component: () => import(
          /* webpackChunkName: "TeamModule" */
          '@/modules/Team/views/TeamList.vue'
        ),
      },
      {
        path: ':id',
        name: 'user',
        component: () => import(
          /* webpackChunkName: "TeamModule" */
          '@/modules/Team/views/TeamDetail.vue'
        ),
      },
      {
        path: 'invite',
        name: 'invite-member',
        component: () => import(
          /* webpackChunkName: "TeamModule" */
          '@/modules/Team/views/InviteMembers/InviteMembers.vue'
        ),
      },
    ],
  },
];

export default routes;
