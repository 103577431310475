const routes = [
  {
    path: '/products',
    name: 'Products',
    component: () => import(
      /* webpackChunkName: "ProductsModule" */
      '@/modules/Products/ProductsModule.vue'
    ),
    meta: {
      mainClasses: 'App-main__withheader',
      hideAside: true,
      hideFooter: true,
      requiresAuth: true,
    },
  },
];

export default routes;
