import { getApiRoute } from '@core/api';
import { AuthState, useAuth } from '@core/utils';

import { thnAppsUrl } from '@/config';

import axios from './axios';
import {
  userDetails as ApiUserDetails,
  partnerDetails as ApiPartnerDetails,
} from '@/api/routes';

export const authCoreService = useAuth({
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
});

export const getBenchDirectRedirectUrl = () => {
  let bdirectUrl = thnAppsUrl.benchdirect;
  if (AuthState.impersonatedUser?.loggedAsUserId) {
    const { loggedAsUserId } = AuthState.impersonatedUser;
    bdirectUrl += `?loginAs=${loggedAsUserId}`;
  }

  return bdirectUrl;
};

export const getPersonalizationRedirectUrl = () => {
  let personalizationUrl = thnAppsUrl.personalization;
  if (AuthState.impersonatedUser?.loggedAsUserId) {
    const { loggedAsUserId } = AuthState.impersonatedUser;
    personalizationUrl += `?loginAs=${loggedAsUserId}`;
  }

  return personalizationUrl;
};

export const getDashboardsRedirectUrl = () => {
  let dashboardsUrl = thnAppsUrl.dashboards;
  if (AuthState.impersonatedUser?.loggedAsUserId) {
    const { loggedAsUserId } = AuthState.impersonatedUser;
    dashboardsUrl += `?loginAs=${loggedAsUserId}`;
  }

  return dashboardsUrl;
};

export const getDemandUrl = () => {
  let demandUrl = thnAppsUrl.demand;
  if (AuthState.impersonatedUser?.loggedAsUserId) {
    const { loggedAsUserId } = AuthState.impersonatedUser;
    demandUrl += `?loginAs=${loggedAsUserId}`;
  }

  return demandUrl;
};

export const redirectToAdmin = (pid, redirectPath) => {
  const adminUrl = process.env.VUE_APP_ADMIN_URL;

  const form = document.createElement('form');
  form.method = 'POST';
  form.action = `${adminUrl}/login-sso`;

  const tokenInput = document.createElement('input');
  tokenInput.type = 'hidden';
  tokenInput.name = 'token';
  tokenInput.value = AuthState.token || '';
  form.appendChild(tokenInput);

  const userIdInput = document.createElement('input');
  userIdInput.type = 'hidden';
  userIdInput.name = 'user_id';
  userIdInput.value = AuthState.userId || '';
  form.appendChild(userIdInput);

  const impersonatedUserIdField = document.createElement('input');
  impersonatedUserIdField.type = 'hidden';
  impersonatedUserIdField.name = 'impersonated_user_id';
  impersonatedUserIdField.value = AuthState.impersonatedUser?.loggedAsUserId || '';
  form.appendChild(impersonatedUserIdField);

  const impersonatedPowerLevelsInput = document.createElement('input');
  impersonatedPowerLevelsInput.type = 'hidden';
  impersonatedPowerLevelsInput.name = 'impersonated_power_levels';
  impersonatedPowerLevelsInput.value = AuthState.impersonatedUser?.loggedWithPowerLevels
    ? 'true'
    : 'false';
  form.appendChild(impersonatedPowerLevelsInput);

  if (pid) {
    const pidInput = document.createElement('input');
    pidInput.type = 'hidden';
    pidInput.name = 'pid';
    pidInput.value = pid;
    form.appendChild(pidInput);
  }

  if (redirectPath) {
    const redirectInput = document.createElement('input');
    redirectInput.type = 'hidden';
    redirectInput.name = 'redirect_path';
    redirectInput.value = redirectPath;
    form.appendChild(redirectInput);
  }

  document.body.appendChild(form);
  form.submit();
};

export const getUserDetails = async (userId) => {
  const url = getApiRoute(ApiUserDetails, { params: { id: userId } });

  try {
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

export const getPartnerDetails = async (email) => {
  const url = getApiRoute(ApiPartnerDetails, { params: { email } });

  try {
    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};
