import enUS from './en-US.json';
import deDE from './de-DE.json';
import esES from './es-ES.json';
import frFR from './fr-FR.json';
import itIT from './it-IT.json';
import nlNL from './nl-NL.json';
import ptPT from './pt-PT.json';

const projectLocales = {
  en: enUS,
  de: deDE,
  es: esES,
  fr: frFR,
  it: itIT,
  nl: nlNL,
  pt: ptPT,
};

export default projectLocales;
