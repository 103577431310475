export const teamList = '/account-history/account/:id/team-member';
export const teamUserDetail = '/account-history/account/:id/team-member/:teamMemberId';
export const teamUserProperties = '/account-history/account/:id/team-member/:teamMemberId/property';
export const teamUserUnassignedProperties = '/account-history/account/:id/team-member/:teamMemberId/unassigned-property';
export const teamUserPropertyAdd = '/property/account/:account/property/:property_id/team-member';
export const teamUserPropertyRemove = '/property/account/:id/property/:property_id/team-member/:team_member_id';
export const teamUserRemove = '/user/account/:account/external-user/:id';
export const changeRole = '/user/account/:account/external-user/:id/change-role';
export const teamInviteUser = '/user/account/:account/invite-external-user';
export const teamInviteUserSendEmail = '/user/account/:account/resend-invitation/:teamMemberId';
