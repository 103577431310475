import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "fixed inset-0 flex items-center justify-center w-full max-w-3xl m-auto"
};
const _hoisted_2 = {
  key: 0,
  class: "title1"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LpLoader = _resolveComponent("LpLoader");

  return _openBlock(), _createElementBlock("div", null, [_ctx.loading ? _renderSlot(_ctx.$slots, "loading", {
    key: 0,
    props: {
      loading: _ctx.loading
    }
  }, () => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_LpLoader, {
    class: "text-primary-500",
    "ring-size": "50"
  })])]) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_renderSlot(_ctx.$slots, "title", {}, () => [_ctx.title ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1)) : _createCommentVNode("", true)]), _createElementVNode("main", null, [_renderSlot(_ctx.$slots, "default")])], 64))]);
}