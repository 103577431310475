import { mutationNames } from './types';

const state = {
  mainMenuOpen: false,
  featureFlags: {},
};

const mutations = {
  [mutationNames.SET_MAIN_MENU_OPEN](state, payload) {
    state.mainMenuOpen = payload;
  },
  [mutationNames.TOGGLE_MAIN_MENU](state) {
    state.mainMenuOpen = !state.mainMenuOpen;
  },
  [mutationNames.SET_FEATURE_FLAGS](state, payload) {
    state.featureFlags = payload;
  },
};

const getters = {};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
