/* eslint-disable camelcase */
import { InjectionKey, Ref } from 'vue';

interface Notification {
  type?: string,
  text: string,
  placement?: string,
  dismissible?: boolean,
  onClick?: () => unknown,
  durationSeconds?: number,
  icon?: string
}

interface ShowNotification {
  open(payload: Notification): void;
  success(payload: Notification): void;
  error(payload: Notification): void;
  warning(payload: Notification): void;
  info(payload: Notification): void;
}

export const PageContext: InjectionKey<{
  isPageLoading: Ref<boolean>;
  showNotification: ShowNotification;
}> = Symbol('PageContext');

interface Pagination {
  amount_per_page: number;
  current_page: number;
  items_left: number;
  pages_left: number;
  total_items: number;
  total_pages: number;
}

export type Metadata = {
  pagination: Pagination
};

export interface ResponseData {
  data: any;
  metadata: Metadata;
}
