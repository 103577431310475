import { useAuthService } from '@/modules/Auth/services/AuthService';

const AuthService = useAuthService();

const routes = [
  {
    path: '/corporate',
    name: 'Corporate',
    component: () => import(
      /* webpackChunkName: "PropertiesModule" */
      '@/modules/Properties/PropertiesModule.vue'
    ),
    meta: {
      mainClasses: 'App-main__withall',
      hideAside: false,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      requiresActiveProperties: true,
      accountSettingsPage: true,
      requiresValidUser: AuthService.isValidUser || AuthService.isPartnerUser,
    },
    children: [
      {
        path: '',
        name: 'corporates',
        component: () => import(
          /* webpackChunkName: "PropertiesModule" */
          '@/modules/Properties/views/PropertyList.vue'
        ),
        meta: {
          propertyType: 'corporate',
        },
      },
      {
        path: ':id',
        name: 'corporate',
        component: () => import(
          /* webpackChunkName: "PropertiesModule" */
          '@/modules/Properties/views/PropertyDetails.vue'
        ),
        meta: {
          propertyType: 'corporate',
        },
      },
      {
        path: ':id/copy-preferences',
        name: 'corporate-copy-preferences',
        component: () => import(
          /* webpackChunkName: "PropertiesModule" */
          '@/modules/Properties/views/PropertyPreferencesCopy.vue'
        ),
        meta: {
          propertyType: 'corporate',
        },
      },
    ],
  },
];

export default routes;
