/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
import { createApp } from 'vue';

import loadRouter from '@core/router';

import projectRoutes from '@/router';

import NkApp from '@/components/NkApp/NkApp.vue';

import { i18n } from '@/i18n';
import { store as appStore } from '@/store';
import { validateAccess } from '@/router/routerGuard';

import './css/main.css';

declare global {
  interface Window {
    Appcues: any; // 👈️ turn off type checking
  }
}

// needed by '@thehotelsnetwork/vue-core/src/dispatcher/actors/asideoverlay.ts'
export const store = appStore;

// Instance exports needed to get acces on non vue files
export const router: any = loadRouter(projectRoutes);

// Router
router.beforeEach(validateAccess);

router.afterEach(() => {
  store?.dispatch('cancelPendingRequests');
});

export const app = createApp(NkApp)
  .use(i18n)
  .use(store)
  .use(router);

// This is to avoid first load with all routes pointing to "/" instead of defined path
router.isReady().then(() => app.mount('#app'));
