import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/accounts',
    name: 'AccountsModule',
    component: () => import(
      /* webpackChunkName: "AccountsModule" */
      '@/modules/Accounts/AccountsModule.vue'
    ),
    meta: {
      hideAside: true,
      hideFooter: true,
      requiresAuth: true,
    },
    children: [
      {
        path: ':accountId?',
        name: 'accounts-select',
        component: () => import(
          /* webpackChunkName: "AccountsModule" */
          '@/modules/Accounts/views/SelectAccount/SelectAccount.vue'
        ),
      },
    ],
  },
];

export default routes;
