import { buildApiRequest } from '@core/api';

import {
  // eslint-disable-next-line import/named
  getterNames,
  API_ACCOUNT_CLUSTERS,
  API_ACCOUNT_BRANDS,
  API_ACCOUNT_CLUSTER,
  API_ACCOUNT_CLUSTER_CREATE,
  API_ACCOUNT_BRAND_CREATE,
  API_ACCOUNT_CLUSTER_UPDATE,
  API_ACCOUNT_CLUSTER_PROPERTIES,
  API_ACCOUNT_CLUSTER_PROPERTY_ADD,
  API_ACCOUNT_CLUSTER_REMOVE,
  API_ACCOUNT_CLUSTER_PROPERTY_DELETE,
  API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER,
  API_GET_ACCOUNT_PROPERTIES_NOBRAND,
  API_ACCOUNT_PROPERTIES,
} from './types';

import axios from '@/utils/axios';
import {
  accountClusters as ApiAccountClusters,
  accountBrands as ApiAccountBrands,
  accountClusterDetails as ApiAccountClusterDetails,
  accountClusterProperties as ApiAccountClusterProperties,
  accountClusterUpdate as ApiAccountClusterUpdate,
  accountClusterCreate as ApiAccountClusterCreate,
  accountBrandCreate as ApiAccountBrandCreate,
  accountClusterPropertyAdd as ApiAccountClusterPropertyAdd,
  accountClusterDelete as ApiAccountClusterDelete,
  accountClusterPropertyDelete as ApiAccountClusterPropertyDelete,
  accountClusterPropertyTransfer as ApiAccountClusterPropertyTransfer,
  accountClusterPropertiesNoBrand as ApiAccountClusterPropertiesNoBrand,
  accountProperties as ApiAccountProperties,
} from '@/api/routes/clusters';

const state = {
  isFetching: false,
  isFetchingProperties: false,
  isFetchingAvailableProperties: false,
  clusters: [],
  cluster: {},
  properties: [],
  availableProperties: [],
};

const getters = {
  [getterNames.CLUSTERS_GET_ACCOUNT_PROPERTIES_NOBRAND]:
  state => state.get_account_properties_nobrand?.isFetching,
};

const mutations = {
  [API_ACCOUNT_CLUSTERS.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_ACCOUNT_CLUSTERS.SUCCESS](state, data) {
    state.clusters = data;
    state.error = null;
    state.isFetching = false;
  },
  [API_ACCOUNT_CLUSTERS.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },

  [API_ACCOUNT_BRANDS.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_ACCOUNT_BRANDS.SUCCESS](state, data) {
    state.clusters = data;
    state.error = null;
    state.isFetching = false;
  },
  [API_ACCOUNT_BRANDS.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },

  [API_ACCOUNT_CLUSTER.REQUEST](state) {
    state.cluster = {};
    state.properties = { data: [] };
    state.error = null;
    state.isFetching = true;
  },
  [API_ACCOUNT_CLUSTER.SUCCESS](state, cluster) {
    state.cluster = {
      id: cluster.id,
      name: cluster.name,
      color: cluster.color,
      propertyCount: cluster.active_properties_count,
      properties: cluster.properties,
      type: cluster.type,
      logo: cluster.logo,
    };
    state.error = null;
    state.isFetching = false;
  },
  [API_ACCOUNT_CLUSTER.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },

  [API_ACCOUNT_CLUSTER_CREATE.REQUEST](state) {
    state.error = null;
  },
  [API_ACCOUNT_CLUSTER_CREATE.SUCCESS](state) {
    state.error = null;
  },
  [API_ACCOUNT_CLUSTER_CREATE.ERROR](state, error) {
    state.error = error;
  },

  [API_ACCOUNT_BRAND_CREATE.REQUEST](state) {
    state.error = null;
  },
  [API_ACCOUNT_BRAND_CREATE.SUCCESS](state) {
    state.error = null;
  },
  [API_ACCOUNT_BRAND_CREATE.ERROR](state, error) {
    state.error = error;
  },

  [API_ACCOUNT_CLUSTER_UPDATE.REQUEST](state) {
    state.error = null;
  },
  [API_ACCOUNT_CLUSTER_UPDATE.SUCCESS](state) {
    state.error = null;
  },
  [API_ACCOUNT_CLUSTER_UPDATE.ERROR](state, error) {
    state.error = error;
  },

  [API_ACCOUNT_CLUSTER_PROPERTIES.REQUEST](state) {
    state.error = null;
    state.isFetchingProperties = true;
  },
  [API_ACCOUNT_CLUSTER_PROPERTIES.SUCCESS](state, properties) {
    state.error = null;
    state.isFetchingProperties = false;
    state.properties = properties;
  },
  [API_ACCOUNT_CLUSTER_PROPERTIES.ERROR](state, error) {
    state.error = error;
    state.isFetchingProperties = false;
  },

  [API_ACCOUNT_CLUSTER_REMOVE.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_ACCOUNT_CLUSTER_REMOVE.SUCCESS](state) {
    state.error = null;
    state.isFetching = false;
  },
  [API_ACCOUNT_CLUSTER_REMOVE.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },

  [API_ACCOUNT_CLUSTER_PROPERTY_ADD.SUCCESS](state) {
    state.error = null;
  },

  [API_ACCOUNT_CLUSTER_PROPERTY_ADD.ERROR](state, error) {
    state.error = error;
  },

  [API_ACCOUNT_CLUSTER_PROPERTY_DELETE.SUCCESS](state) {
    state.error = null;
  },

  [API_ACCOUNT_CLUSTER_PROPERTY_DELETE.ERROR](state, error) {
    state.error = error;
  },

  [API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER.REQUEST](state) {
    state.error = null;
  },
  [API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER.SUCCESS](state) {
    state.error = null;
  },
  [API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER.ERROR](state, error) {
    state.error = error;
  },

  [API_ACCOUNT_PROPERTIES.REQUEST](state) {
    state.error = null;
    state.isFetchingAvailableProperties = true;
    state.availableProperties = [];
  },
  [API_ACCOUNT_PROPERTIES.SUCCESS](state, properties) {
    state.error = null;
    state.isFetchingAvailableProperties = false;
    state.availableProperties = properties;
  },
  [API_ACCOUNT_PROPERTIES.ERROR](state, error) {
    state.isFetchingAvailableProperties = false;
    state.error = error;
  },
};

const actions = {
  async [API_ACCOUNT_CLUSTERS.REQUEST]({ commit }, {
    account,
    resultsPerPage = 10,
    pageNumber = 1,
    filterString = '',
  }) {
    try {
      commit(API_ACCOUNT_CLUSTERS.REQUEST);

      const url = ApiAccountClusters.replace(':account', account.id);
      const { data } = await axios.get(`${url}?per_page=${resultsPerPage}&page_num=${pageNumber}&filters[0][field]=name&filters[0][operator]=like&filters[0][value]=${filterString}`);

      if (!data) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_CLUSTERS.SUCCESS, data);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTERS.ERROR, error);
    }
  },
  async [API_ACCOUNT_BRANDS.REQUEST]({ commit }, {
    account,
    resultsPerPage = 10,
    pageNumber = 1,
    filterString = '',
  }) {
    try {
      commit(API_ACCOUNT_BRANDS.REQUEST);

      const url = ApiAccountBrands.replace(':account', account.id);
      const { data } = await axios.get(`${url}?per_page=${resultsPerPage}&page_num=${pageNumber}&filters[0][field]=name&filters[0][operator]=like&filters[0][value]=${filterString}`);

      if (!data) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_BRANDS.SUCCESS, data);
    } catch (error) {
      commit(API_ACCOUNT_BRANDS.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER.REQUEST]({ commit }, { account, cluster }) {
    try {
      commit(API_ACCOUNT_CLUSTER.REQUEST);

      const url = ApiAccountClusterDetails.replace(':id', account.id).replace(':cluster', cluster);
      const { data: clusterData } = await axios.get(url);

      if (!clusterData) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_CLUSTER.SUCCESS, clusterData);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER_PROPERTIES.REQUEST]({ commit }, {
    account,
    cluster,
    resultsPerPage = 10,
    pageNumber = 1,
    filterString = '',
  }) {
    try {
      commit(API_ACCOUNT_CLUSTER_PROPERTIES.REQUEST);

      const propertiesUrl = ApiAccountClusterProperties
        .replace(':id', account.id)
        .replace(':cluster', cluster);

      const { data } = await axios.get(
        `${propertiesUrl}?per_page=${resultsPerPage}&page_num=${pageNumber}&filters[0][field]=name&filters[0][operator]=like&filters[0][value]=${filterString}`,
      );
      if (!data) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_CLUSTER_PROPERTIES.SUCCESS, data);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER_PROPERTIES.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER_CREATE.REQUEST]({ commit }, { account, name, propertyIds }) {
    try {
      commit(API_ACCOUNT_CLUSTER_CREATE.REQUEST);

      const url = ApiAccountClusterCreate.replace(':id', account);
      const { data } = await axios.post(url, {
        name,
        property_ids: propertyIds,
      });

      if (!data) {
        throw new Error('no data returned');
      }

      const { cluster_id: clusterId } = data;

      commit(API_ACCOUNT_CLUSTER_CREATE.SUCCESS, clusterId);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER_CREATE.ERROR, error);
    }
  },
  async [API_ACCOUNT_BRAND_CREATE.REQUEST]({ commit }, { account, name, propertyIds }) {
    try {
      commit(API_ACCOUNT_BRAND_CREATE.REQUEST);

      const url = ApiAccountBrandCreate.replace(':id', account);
      const { data } = await axios.post(url, {
        name,
        property_ids: propertyIds,
      });

      if (!data) {
        throw new Error('no data returned');
      }

      const { cluster_id: clusterId } = data;

      commit(API_ACCOUNT_BRAND_CREATE.SUCCESS, clusterId);
    } catch (error) {
      commit(API_ACCOUNT_BRAND_CREATE.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER_UPDATE.REQUEST]({ commit }, {
    account,
    cluster,
    name,
    logo,
    color,
  }) {
    try {
      commit(API_ACCOUNT_CLUSTER_UPDATE.REQUEST);

      const url = ApiAccountClusterUpdate.replace(':id', account.id).replace(':cluster', cluster);
      const { data } = await axios.put(url, {
        name,
        logo_64: logo,
        color: color?.replace('#', ''),
      });

      if (!data) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_CLUSTER_UPDATE.SUCCESS, data.data);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER_UPDATE.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER_PROPERTY_ADD.REQUEST]({ commit }, {
    account,
    cluster,
    property,
  }) {
    try {
      commit(API_ACCOUNT_CLUSTER_UPDATE.REQUEST);

      const url = ApiAccountClusterPropertyAdd.replace(':id', account.id).replace(':cluster', cluster);
      await axios.post(url, { property_id: property });

      commit(API_ACCOUNT_CLUSTER_PROPERTY_ADD.SUCCESS, property);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER_REMOVE.REQUEST]({ commit }, { account, cluster }) {
    try {
      commit(API_ACCOUNT_CLUSTER_REMOVE.REQUEST);

      const url = ApiAccountClusterDelete.replace(':account', account.id).replace(':id', cluster);
      await axios.delete(url);

      commit(API_ACCOUNT_CLUSTER_REMOVE.SUCCESS);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER_REMOVE.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER_PROPERTY_DELETE.REQUEST]({ commit }, {
    account,
    cluster,
    property,
  }) {
    try {
      commit(API_ACCOUNT_CLUSTER_UPDATE.REQUEST);

      const url = ApiAccountClusterPropertyDelete.replace(':id', account.id).replace(':cluster', cluster);
      await axios.post(url, { property_id: property });

      // dispatch(API_ACCOUNT_CLUSTER_PROPERTIES.REQUEST, { account, cluster });
      commit(API_ACCOUNT_CLUSTER_PROPERTY_DELETE.SUCCESS, property);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER.ERROR, error);
    }
  },
  async [API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER.REQUEST]({ commit }, {
    account,
    property,
    destination,
  }) {
    try {
      commit(API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER.REQUEST);

      const url = ApiAccountClusterPropertyTransfer.replace(':id', account.id).replace(':property', property);
      await axios.post(url, { brand_id: destination });

      // dispatch(API_ACCOUNT_CLUSTER_PROPERTIES.REQUEST, { account, cluster });
      commit(API_ACCOUNT_CLUSTER_PROPERTY_TRANSFER.SUCCESS);
    } catch (error) {
      commit(API_ACCOUNT_CLUSTER.ERROR, error);
    }
  },
  async [API_ACCOUNT_PROPERTIES.REQUEST]({ commit }, {
    account,
    resultsPerPage = 10,
    pageNumber = 1,
  }) {
    try {
      commit(API_ACCOUNT_PROPERTIES.REQUEST);

      const { team_member_id: teamMemberId } = account;

      const propertiesUrl = ApiAccountProperties.replace(':id', account.id).replace(':teamMemberId', teamMemberId);
      const { data } = await axios.get(`${propertiesUrl}?per_page=${resultsPerPage}&page_num=${pageNumber}`);
      if (!data) {
        throw new Error('no data returned');
      }

      commit(API_ACCOUNT_PROPERTIES.SUCCESS, data);
    } catch (error) {
      commit(API_ACCOUNT_PROPERTIES.ERROR, error);
    }
  },
};

const clustersModule = {
  state,
  getters,
  mutations,
  actions,
};

buildApiRequest(
  clustersModule,
  API_GET_ACCOUNT_PROPERTIES_NOBRAND,
  ApiAccountClusterPropertiesNoBrand,
);

export default clustersModule;
