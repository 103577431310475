export const accountCreate = '/property/account';
export const accountUpdate = '/property/account/:account';

// Account History
export const accountExists = '/account-history/account/exists';
export const accountSelect = '/auth/select-account';
export const accountsByUser = '/account-history/user/:id/account';
export const accountById = '/account-history/account/:id';
export const accountOverview = '/account-history/account/:id/overview';
export const accountsAll = '/account-history/account';
export const accountProducts = '/account-history/account/:accountId/product';
export const accountUserExists = '/account-history/account/:id/user-exists';
export const getPartnerUserProperties = '/account-history/account/:id/partner/:partnerId/properties';
