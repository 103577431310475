import { buildApiTypes } from '@core/api';

export const { actionTypes: API_GET_TEAM_LIST } = buildApiTypes('team/API_GET_TEAM_LIST');
export const { actionTypes: API_GET_TEAM_USER_DETAIL } = buildApiTypes('team/API_GET_TEAM_USER_DETAIL');
export const { actionTypes: API_GET_TEAM_USER_PROPERTIES } = buildApiTypes('team/API_GET_TEAM_USER_PROPERTIES');
export const { actionTypes: API_TEAM_USER_REMOVE } = buildApiTypes('team/API_TEAM_USER_REMOVE');
export const { actionTypes: API_TEAM_USER_CHANGE_ROLE } = buildApiTypes('team/API_TEAM_USER_CHANGE_ROLE');
export const { actionTypes: API_GET_TEAM_USER_UNASSIGNED_PROPERTIES } = buildApiTypes('team/API_GET_TEAM_USER_UNASSIGNED_PROPERTIES');
export const { actionTypes: API_TEAM_USER_PROPERTY_ADD } = buildApiTypes('team/API_TEAM_USER_PROPERTY_ADD');
export const { actionTypes: API_TEAM_USER_PROPERTY_REMOVE } = buildApiTypes('team/API_TEAM_USER_PROPERTY_REMOVE');
export const { actionTypes: API_TEAM_USER_INVITE_USER } = buildApiTypes('team/API_TEAM_USER_INVITE_USER');
export const { actionTypes: API_POST_USER_INVITE_RESEND } = buildApiTypes('team/API_POST_USER_INVITE_RESEND');

export const getterNames = {
  TEAM_GET_TEAM_LIST_FETCHING: 'team/GET_TEAM_LIST_FETCHING',
  TEAM_GET_TEAM_LIST: 'team/GET_TEAM_LIST',
  TEAM_GET_TEAM_USER_PROPERTIES_FETCHING: 'team/GET_TEAM_USER_PROPERTIES_FETCHING',
  TEAM_GET_TEAM_USER_PROPERTIES: 'team/GET_TEAM_USER_PROPERTIES',
  TEAM_GET_TEAM_USER_DETAIL_FETCHING: 'team/GET_TEAM_USER_DETAIL_FETCHING',
  TEAM_GET_TEAM_USER_DETAIL: 'team/GET_TEAM_USER_DETAIL',
  TEAM_GET_TEAM_USER_UNASSIGNED_PROPERTIES_FETCHING: 'team/GET_TEAM_USER_UNASSIGNED_PROPERTIES_FETCHING',
};
