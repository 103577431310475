/* eslint-disable @typescript-eslint/no-shadow */
import { watch } from 'vue';

import { useEventSource } from '@vueuse/core';

import { buildApiRequest } from '@core/api';

import {
  API_SIGNIN_ACCOUNT,
  API_USER_CHANGE_PASSWORD,
  API_USER_UPDATE_PROFILE,
  API_USER_RESET_PASSWORD,
  API_USER_VALIDATE_HASH,
  API_GET_VALIDATE_HASH_FROM_INVITE,
  API_LOGOUT_USER,
  API_GET_ACCOUNT_USER_EXISTS,
  API_GET_USER_DETAILS,
  API_POST_USER_APPROVE,
  API_GET_HELP_CENTER,
} from './types';

import axios from '@/utils/axios';

import {
  accountCreate as apiAccountCreate,
  userChangePassword as apiUserChangePassword,
  userUpdateProfile as apiUserUpdateProfile,
  userResetPassword as apiUserResetPassword,
  userValidateHash as apiUserValidateHash,
  userValidateHashFromInvite as apiUserValidateHashFromInvite,
  accountUserExists as ApiAccountUserExists,
  userDetails as ApiUserDetails,
  userApprove as ApiUserApprove,
  helpCenter as ApiHelpCenter,
} from '@/api/routes';
import { apiURL } from '@/config';

const state = {
  isFetching: false,
  error: null,
};

const getters = {};

const mutations = {
  [API_SIGNIN_ACCOUNT.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_SIGNIN_ACCOUNT.SUCCESS](state, account) {
    state.account = account;
    state.isFetching = false;
  },
  [API_SIGNIN_ACCOUNT.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },
  [API_USER_CHANGE_PASSWORD.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_USER_CHANGE_PASSWORD.SUCCESS]() {
    state.error = null;
    state.isFetching = false;
  },
  [API_USER_CHANGE_PASSWORD.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },
  [API_USER_UPDATE_PROFILE.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_USER_UPDATE_PROFILE.SUCCESS](state) {
    state.error = null;
    state.isFetching = false;
  },
  [API_USER_UPDATE_PROFILE.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },
  [API_USER_RESET_PASSWORD.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_USER_RESET_PASSWORD.SUCCESS]() {
    state.error = null;
    state.isFetching = false;
  },
  [API_USER_RESET_PASSWORD.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },
  [API_USER_VALIDATE_HASH.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_USER_VALIDATE_HASH.SUCCESS]() {
    state.error = null;
    state.isFetching = false;
  },
  [API_USER_VALIDATE_HASH.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },
  [API_LOGOUT_USER](state) {
    state.account = {};
  },
};

const actions = {
  async [API_USER_VALIDATE_HASH.POST]({ commit }, { hash }) {
    try {
      commit(API_USER_VALIDATE_HASH.REQUEST);
      await axios.get(`${apiUserValidateHash}?hash=${hash}`);

      commit(API_USER_VALIDATE_HASH.SUCCESS);
    } catch (error) {
      commit(API_USER_VALIDATE_HASH.ERROR, error);
    }
  },

  async [API_USER_RESET_PASSWORD.POST]({ commit }, { hash, password }) {
    try {
      commit(API_USER_RESET_PASSWORD.REQUEST);
      await axios.post(apiUserResetPassword, {
        hash,
        password,
      });

      commit(API_USER_RESET_PASSWORD.SUCCESS);
    } catch (error) {
      commit(API_USER_RESET_PASSWORD.ERROR, error);
    }
  },

  async [API_SIGNIN_ACCOUNT.POST]({ commit }, account) {
    const { id, name, ownerDetails } = account;

    const url = new URL(apiURL.mercure);
    url.searchParams.append('topic', 'account_approved');
    url.searchParams.append('topic', 'account_rejected');
    const {
      data: eventSourceData,
      error: eventSourceError,
      close: eventSourceClose,
    } = useEventSource(url);

    try {
      commit(API_SIGNIN_ACCOUNT.REQUEST);

      watch([eventSourceData, eventSourceError], (values) => {
        const [message, error] = values;

        if (error) throw error;

        const { data: eventData } = JSON.parse(message || '{}');
        if (eventData?.owner_details?.email === ownerDetails.email) {
          if (eventData?.status === 'APPROVED') {
            commit(API_SIGNIN_ACCOUNT.SUCCESS, eventData);
          } else {
            commit(API_SIGNIN_ACCOUNT.ERROR, eventData.error_message);
          }
          eventSourceClose();
        }
      });

      const { data } = await axios.post(apiAccountCreate, {
        id,
        name,
        owner_details: {
          first_name: ownerDetails.firstName,
          last_name: ownerDetails.lastName,
          email: ownerDetails.email,
        },
      });

      if (!data || !data.account_id) {
        throw new Error('account_id not created');
      }
    } catch (error) {
      eventSourceClose();
      commit(API_SIGNIN_ACCOUNT.ERROR, error);
    }
  },

  async [API_LOGOUT_USER]({ commit, dispatch, rootState }) {
    rootState.accounts.accounts = [];
    rootState.accounts.account = {};

    commit('auth/API_LOGOUT_USER');
    dispatch('logout');
  },

  async [API_USER_CHANGE_PASSWORD.POST]({ commit }, userData) {
    try {
      commit(API_USER_CHANGE_PASSWORD.REQUEST);

      const { userId, oldPassword, newPassword } = userData;
      const url = apiUserChangePassword.replace(':id', userId);
      await axios.post(url, {
        old_password: oldPassword,
        new_password: newPassword,
      });

      commit(API_USER_CHANGE_PASSWORD.SUCCESS);
    } catch (error) {
      commit(API_USER_CHANGE_PASSWORD.ERROR, error);
    }
  },

  async [API_USER_UPDATE_PROFILE.POST]({ commit, rootState }, profile) {
    try {
      commit(API_USER_UPDATE_PROFILE.REQUEST);

      const url = apiUserUpdateProfile.replace(':id', profile.id);
      await axios.put(url, { ...profile });

      const { user } = rootState.auth;
      rootState.auth.user = {
        ...user,
        ...profile,
      };

      commit(API_USER_UPDATE_PROFILE.SUCCESS);
    } catch (error) {
      commit(API_USER_UPDATE_PROFILE.ERROR, error);
    }
  },
};

const authModule = {
  state,
  mutations,
  actions,
  getters,
};

buildApiRequest(authModule, API_GET_ACCOUNT_USER_EXISTS, ApiAccountUserExists);

buildApiRequest(authModule, API_GET_USER_DETAILS, ApiUserDetails);

buildApiRequest(authModule, API_GET_VALIDATE_HASH_FROM_INVITE, apiUserValidateHashFromInvite);

buildApiRequest(authModule, API_POST_USER_APPROVE, ApiUserApprove);

buildApiRequest(authModule, API_GET_HELP_CENTER, ApiHelpCenter);

export default authModule;
