import { ref } from 'vue';
import { format } from 'date-fns';

export const loggerMessages = ref([]);

export const useLogger = (module = 'CORE') => {
  const log = (type, ...args) => {
    if (process.env.NODE_ENV !== 'production') {
      const now = format(new Date(), 'hh:ii:ss');
      // eslint-disable-next-line no-console
      console[type](`[${now}][${module}]:`, ...args);
    }
  };

  const logInfo = (...args) => {
    log('info', ...args);
  };

  const logError = (...args) => {
    log('error', ...args);
  };

  return {
    logInfo,
    logError,
  };
};
