import { store } from '@/store';

import { API_GET_HELP_CENTER } from '@/modules/Auth/store/types';

/**
 * Navigate to a given path
 * @param path - path to navigate to
 * @param options - optional parameters for the navigation
 */
export const navigate = (path, options = {}) => {
  if (typeof path !== 'string' || !path) {
    return;
  }
  let url = path;
  if (options.query !== undefined) {
    const queryString = Object.keys(options.query)
      .map((key) => {
        const value = options.query ? options.query[key] : '';
        return `${key}=${value}`;
      })
      .join('&');
    url += `?${queryString}`;
  }

  if (options.newTab) {
    window.open(url, '_blank')?.focus();
  } else {
    window.location.href = url;
  }
};

/**
 * Navigate to a given Help Center page
 * @param page - HC page to navigate to
 */
export const navigateToHc = async (page, newTab = true) => {
  const { data } = await store.dispatch(
    API_GET_HELP_CENTER.REQUEST,
    {
      query: {
        page,
      },
    },
  );
  navigate(data.data.link, { newTab });
};
