import { buildApiRequest } from '@core/api';

import {
  API_GET_ACCOUNT,
  API_ACCOUNT_UPDATE,
} from './types';

import axios from '@/utils/axios';
import {
  accountById as apiAccountById,
  accountUpdate as apiAccountUpdate,
} from '@/api/routes/accounts';

const state = {
  isFetching: false,
  error: {},
};

const getters = {};

const mutations = {
  [API_ACCOUNT_UPDATE.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_ACCOUNT_UPDATE.SUCCESS](state) {
    state.error = null;
    state.isFetching = false;
  },
  [API_ACCOUNT_UPDATE.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },
};

const actions = {
  async [API_ACCOUNT_UPDATE.REQUEST]({ commit }, {
    account,
    name,
    logo,
  }) {
    try {
      commit(API_ACCOUNT_UPDATE.REQUEST);

      const url = apiAccountUpdate.replace(':account', account.id);
      await axios.put(url, {
        name,
        logo_64: logo,
      });

      commit(API_ACCOUNT_UPDATE.SUCCESS);
    } catch (error) {
      commit(API_ACCOUNT_UPDATE.ERROR, error);
    }
  },
};

const settingsModule = {
  state,
  getters,
  mutations,
  actions,
};

buildApiRequest(settingsModule, API_GET_ACCOUNT, apiAccountById);

export default settingsModule;
