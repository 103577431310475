const routes = [
  {
    path: '/auth',
    name: 'authModule',
    component: () => import(
      /* webpackChunkName: "AuthModule" */
      '@/modules/Auth/AuthModule.vue'
    ),
    meta: {
      hideAside: true,
      hideHeader: true,
      hideFooter: true,
    },
    children: [
      {
        path: '/signup',
        name: 'signup',
        meta: {
          mainClasses: 'testing',
          requiresAuth: true,
        },
        component: () => import(
          /* webpackChunkName: "AuthModule" */
          '@/modules/Auth/views/Signup/Signup.vue'
        ),
      },
      {
        path: '/logout',
        name: 'logout',
        meta: {
          hideAside: true,
          hideHeader: true,
          hideFooter: true,
          requiresAuth: true,
        },
        component: () => import(
          /* webpackChunkName: "AuthModule" */
          '@/modules/Auth/views/Logout/Logout.vue'
        ),
      },
      {
        path: '/forgot',
        name: 'forgot',
        component: () => import(
          /* webpackChunkName: "AuthModule" */
          '@/modules/Auth/views/Forgot/Forgot.vue'
        ),
      },
      {
        path: '/reset-password',
        name: 'reset',
        component: () => import(
          /* webpackChunkName: "AuthModule" */
          '@/modules/Auth/views/Reset/Reset.vue'
        ),
      },
      {
        path: '/invite',
        name: 'invite',
        component: () => import(
          /* webpackChunkName: "AuthModule" */
          '@/modules/Auth/views/Invite/Invite.vue'
        ),
      },
    ],
  },
];

export default routes;
