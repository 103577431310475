import { ref, provide, defineComponent } from 'vue';
import { showNotification } from '@core/dispatcher';
import { PageContext } from '@/types';
export default defineComponent({
  name: 'NkPage',
  props: {
    loading: {
      type: Boolean
    },
    title: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const isPageLoading = ref(props.loading);
    provide(PageContext, {
      isPageLoading,
      showNotification
    });
    return {
      isPageLoading
    };
  }

});