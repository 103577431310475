import { buildApiTypes } from '@core/api';

export const { actionTypes: API_GET_ACCOUNTS } = buildApiTypes('accounts/API_GET_ACCOUNTS');
export const { actionTypes: API_GET_PARTNER_USER_PROPERTIES } = buildApiTypes('accounts/API_GET_PARTNER_USER_PROPERTIES');
export const { actionTypes: API_POST_SET_ACCOUNT } = buildApiTypes('accounts/API_POST_SET_ACCOUNT');

export const SET_STATE_ACCOUNTS = 'accounts/SET_STATE_ACCOUNTS';
export const SET_STATE_ACCOUNT = 'accounts/SET_STATE_ACCOUNT';

export const getterNames = {
  ACCOUNT_GET_PARTNER_USER_PROPERTIES_FETCHING: 'accounts/GET_PARTNER_USER_PROPERTIES_FETCHING',
};
