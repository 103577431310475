import { computed } from 'vue';

import { i18n } from '@/i18n';
import { useAccountsService } from '@/modules/Accounts/services/AccountsService';
import { useAuthService } from '@/modules/Auth/services/AuthService';

import { useLaunchDarklyService } from '@/services/launchDarkly';

export const useUserMenu = () => {
  const { t } = i18n.global;
  const accountsService = useAccountsService();
  const authService = useAuthService();

  const isSectionVisible = computed(
    () => accountsService.isValidAccountSelected && authService.isValidUser,
  );

  const account = computed(() => [
    {
      to: '/properties',
      description: t('Properties'),
      visible: isSectionVisible.value || authService.isPartnerUser,
    },
    {
      to: '/corporate',
      description: t('corporate sites'),
      visible: isSectionVisible.value || authService.isPartnerUser,
    },
    {
      to: '/brands',
      description: t('Brands'),
      visible: isSectionVisible.value,
    },
    {
      to: '/clusters',
      description: t('Clusters'),
      visible: isSectionVisible.value,
    },
    {
      to: '/team',
      description: t('Team'),
      visible: accountsService.isOwnerOrAdmin && authService.isValidUser,
    },
    {
      to: '/billing',
      description: t('Billing'),
      visible: accountsService.isOwnerOrAdmin
        && authService.isValidUser
        && useLaunchDarklyService().featureFlags.salesforce,
    },
    {
      to: '/settings',
      description: t('Settings'),
      visible: accountsService.isOwnerOrAdmin,
    },
  ]);

  return computed(() => [
    {
      id: 'account',
      description: t('Account'),
      items: account.value.filter(item => item.visible),
    },
  ]);
};
