import { buildApiRequest } from '@core/api';

import {
  // eslint-disable-next-line import/named
  getterNames,
  API_GET_TEAM_LIST,
  API_GET_TEAM_USER_DETAIL,
  API_GET_TEAM_USER_PROPERTIES,
  API_TEAM_USER_REMOVE,
  API_TEAM_USER_CHANGE_ROLE,
  API_GET_TEAM_USER_UNASSIGNED_PROPERTIES,
  API_TEAM_USER_PROPERTY_ADD,
  API_TEAM_USER_PROPERTY_REMOVE,
  API_TEAM_USER_INVITE_USER,
  API_POST_USER_INVITE_RESEND,
} from './types';

import axios from '@/utils/axios';
import {
  teamList as apiTeamList,
  teamUserDetail as apiTeamUserDetail,
  teamUserProperties as apiTeamUserProperties,
  teamUserRemove as apiTeamUserRemove,
  changeRole as apiChangeRole,
  teamUserUnassignedProperties as apiUnassignedProperties,
  teamUserPropertyAdd as apiTeamUserPropertyAdd,
  teamUserPropertyRemove as apiTeamUserPropertyRemove,
  teamInviteUser as apiTeamInviteUser,
  teamInviteUserSendEmail as apiTeamInviteUserSendEmail,
} from '@/api/routes/team';

const state = {
  isFetching: false,
  isFetchingProperties: false,
  isSendingUserInvitatition: false,
};

const getters = {
  [getterNames.TEAM_GET_TEAM_LIST_FETCHING]:
    state => state.get_team_list?.isFetching,
  [getterNames.TEAM_GET_TEAM_LIST]:
    state => state.get_team_list?.response?.data?.data,
  [getterNames.TEAM_GET_TEAM_USER_DETAIL_FETCHING]:
    state => state.get_team_user_detail?.isFetching,
  [getterNames.TEAM_GET_TEAM_USER_DETAIL]:
    state => state.get_team_user_detail?.response?.data,
  [getterNames.TEAM_GET_TEAM_USER_PROPERTIES_FETCHING]:
    state => state.get_team_user_properties?.isFetching,
  [getterNames.TEAM_GET_TEAM_USER_PROPERTIES]:
    state => state.get_team_user_properties?.response?.data?.data,
  [getterNames.TEAM_GET_TEAM_USER_UNASSIGNED_PROPERTIES_FETCHING]:
    state => state?.get_team_user_unassigned_properties?.isFetching,
};

const mutations = {
  [API_TEAM_USER_REMOVE.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_TEAM_USER_REMOVE.SUCCESS](state) {
    state.error = null;
    state.isFetching = false;
  },
  [API_TEAM_USER_REMOVE.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },

  [API_TEAM_USER_CHANGE_ROLE.REQUEST](state) {
    state.error = null;
    state.isFetching = true;
  },
  [API_TEAM_USER_CHANGE_ROLE.SUCCESS](state) {
    state.error = null;
    state.isFetching = false;
  },
  [API_TEAM_USER_CHANGE_ROLE.ERROR](state, error) {
    state.error = error;
    state.isFetching = false;
  },

  [API_TEAM_USER_PROPERTY_ADD.REQUEST](state) {
    state.error = null;
    state.isFetchingProperties = true;
  },
  [API_TEAM_USER_PROPERTY_ADD.SUCCESS](state) {
    state.error = null;
    state.isFetchingProperties = false;
  },
  [API_TEAM_USER_PROPERTY_ADD.ERROR](state, error) {
    state.error = error;
    state.isFetchingProperties = false;
  },

  [API_TEAM_USER_PROPERTY_REMOVE.REQUEST](state) {
    state.error = null;
    state.isFetchingProperties = true;
  },
  [API_TEAM_USER_PROPERTY_REMOVE.SUCCESS](state) {
    state.error = null;
    state.isFetchingProperties = false;
  },
  [API_TEAM_USER_PROPERTY_REMOVE.ERROR](state, error) {
    state.error = error;
    state.isFetchingProperties = false;
  },

  [API_TEAM_USER_INVITE_USER.REQUEST](state) {
    state.error = null;
    state.isSendingUserInvitatition = true;
  },
  [API_TEAM_USER_INVITE_USER.SUCCESS](state) {
    state.error = null;
    state.isSendingUserInvitatition = false;
  },
  [API_TEAM_USER_INVITE_USER.ERROR](state, error) {
    state.error = error;
    state.isSendingUserInvitatition = false;
  },
};

const actions = {
  async [API_TEAM_USER_REMOVE.REQUEST]({ commit }, {
    accountId,
    teamMemberId,
  }) {
    try {
      commit(API_TEAM_USER_REMOVE.REQUEST);

      const url = apiTeamUserRemove.replace(':account', accountId).replace(':id', teamMemberId);
      await axios.delete(url);

      commit(API_TEAM_USER_REMOVE.SUCCESS);
    } catch (error) {
      commit(API_TEAM_USER_REMOVE.ERROR, error);
    }
  },
  async [API_TEAM_USER_CHANGE_ROLE.REQUEST]({ commit }, {
    accountId,
    teamMemberId,
    role,
  }) {
    try {
      commit(API_TEAM_USER_CHANGE_ROLE.REQUEST);

      const url = apiChangeRole.replace(':account', accountId).replace(':id', teamMemberId);
      await axios.put(url, {
        role,
      });

      commit(API_TEAM_USER_CHANGE_ROLE.SUCCESS);
    } catch (error) {
      commit(API_TEAM_USER_CHANGE_ROLE.ERROR, error);
    }
  },
  async [API_TEAM_USER_PROPERTY_ADD.REQUEST]({ commit }, {
    accountId,
    teamMemberId,
    propertyId,
  }) {
    try {
      commit(API_TEAM_USER_PROPERTY_ADD.REQUEST);

      const url = apiTeamUserPropertyAdd.replace(':account', accountId).replace(':property_id', propertyId);
      await axios.post(url, { team_member_id: teamMemberId });

      commit(API_TEAM_USER_PROPERTY_ADD.SUCCESS);
    } catch (error) {
      commit(API_TEAM_USER_PROPERTY_ADD.ERROR, error);
    }
  },
  async [API_TEAM_USER_PROPERTY_REMOVE.REQUEST]({ commit }, {
    accountId,
    teamMemberId,
    propertyId,
  }) {
    try {
      commit(API_TEAM_USER_PROPERTY_REMOVE.REQUEST);

      const url = apiTeamUserPropertyRemove
        .replace(':id', accountId)
        .replace(':property_id', propertyId)
        .replace(':team_member_id', teamMemberId);
      await axios.delete(url);

      commit(API_TEAM_USER_PROPERTY_REMOVE.SUCCESS);
    } catch (error) {
      commit(API_TEAM_USER_PROPERTY_REMOVE.ERROR, error);
    }
  },
  async [API_TEAM_USER_INVITE_USER.REQUEST]({ commit }, { accountId, invitation }) {
    try {
      commit(API_TEAM_USER_INVITE_USER.REQUEST);

      const url = apiTeamInviteUser.replace(':account', accountId);
      await axios.post(url, {
        email: invitation.email,
        role: invitation.role,
        all_properties: invitation.allProperties,
        property_ids: invitation.propertyIds,
        send_email: invitation.send_email,
      });

      commit(API_TEAM_USER_INVITE_USER.SUCCESS);
    } catch (error) {
      commit(API_TEAM_USER_INVITE_USER.ERROR, error);
    }
  },
};

const teamModule = {
  state,
  getters,
  mutations,
  actions,
};

buildApiRequest(
  teamModule,
  API_GET_TEAM_LIST,
  apiTeamList,
);

buildApiRequest(
  teamModule,
  API_GET_TEAM_USER_DETAIL,
  apiTeamUserDetail,
);

buildApiRequest(
  teamModule,
  API_GET_TEAM_USER_PROPERTIES,
  apiTeamUserProperties,
);

buildApiRequest(
  teamModule,
  API_GET_TEAM_USER_UNASSIGNED_PROPERTIES,
  apiUnassignedProperties,
);

buildApiRequest(
  teamModule,
  API_POST_USER_INVITE_RESEND,
  apiTeamInviteUserSendEmail,
);

export default teamModule;
