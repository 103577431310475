import axios from '@/utils/axios';
import { accountProducts as apiAccountProducts } from '@/api/routes';

import { API_GET_ACCOUNT_PRODUCTS } from './types';

const state = {
  isFetching: false,
  error: false,
  products: [],
};

const getters = {};

const mutations = {
  [API_GET_ACCOUNT_PRODUCTS.REQUEST](state) {
    state.isFetching = true;
    state.error = null;
  },
  [API_GET_ACCOUNT_PRODUCTS.SUCCESS](state, products) {
    state.products = products;
    state.isFetching = false;
    state.error = null;
  },
  [API_GET_ACCOUNT_PRODUCTS.ERROR](state, error) {
    state.isFetching = false;
    state.error = error;
  },
};

const actions = {
  async [API_GET_ACCOUNT_PRODUCTS.REQUEST]({ commit }, accountId) {
    try {
      commit(API_GET_ACCOUNT_PRODUCTS.REQUEST);

      const url = apiAccountProducts.replace(':accountId', accountId);
      const { data } = await axios.get(url);

      commit(API_GET_ACCOUNT_PRODUCTS.SUCCESS, data);
    } catch (error) {
      commit(API_GET_ACCOUNT_PRODUCTS.ERROR, error);
    }
  },
};

const productsModule = {
  state,
  mutations,
  actions,
  getters,
};

export default productsModule;
