const routes = [
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import(
      /* webpackChunkName: "Notifications" */
      '@/modules/Notifications/Notifications.vue'
    ),
    meta: {
      mainClasses: 'App-main__withall',
      hideAside: false,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      requiresActiveProperties: true,
      accountSettingsPage: true,
      forTeamMembersOnly: true,
    },
  },
];

export default routes;
