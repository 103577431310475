/* eslint-disable implicit-arrow-linebreak */
import {
  computed,
  reactive,
  ComputedRef,
  UnwrapNestedRefs,
} from 'vue';

import * as LDClient from 'launchdarkly-js-client-sdk';
import { AuthState } from '@thehotelsnetwork/vue-core/src/utils';

import { store } from '@/store';
import { User } from '@/modules/Auth/types';
import { mutationNames } from '@/store/UI/types';

interface LaunchDarklyService {
  featureFlags: ComputedRef<LDClient.LDFlagSet>;
  setFlags: (flags: LDClient.LDFlagSet) => void;
  initClient: (userInfo: User) => Promise<unknown>;
}

export function useLaunchDarklyService(): UnwrapNestedRefs<LaunchDarklyService> {
  const featureFlags = computed(() => store.state.ui.featureFlags);

  function setFlags(flags: LDClient.LDFlagSet) {
    store.commit(mutationNames.SET_FEATURE_FLAGS, flags);
  }

  function initClient(userInfo: User) {
    return new Promise((resolve) => {
      if (!userInfo) {
        throw new Error('Invalid user info');
      }

      const context: LDClient.LDContext = {
        kind: 'user',
        key: userInfo.id,
        firstName: userInfo.first_name,
        lastName: userInfo.last_name,
        email: userInfo.email,
        isLoginAs: !!AuthState.impersonatedUser?.loggedAsUserId,
        app: 'account_settings',
      };

      const clientKey = process.env.VUE_APP_LAUNCHDARKLY_API_KEY;

      if (!clientKey) {
        throw new Error('Invalid client key');
      }

      const client = LDClient.initialize(clientKey, context);

      client.on('ready', () => {
        setFlags(client.allFlags());
        resolve(true);
      });

      client.on('change', () => {
        setFlags(client.allFlags());
      });
    });
  }

  return reactive({
    featureFlags,
    setFlags,
    initClient,
  });
}
