import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LpMarkerBtn = _resolveComponent("LpMarkerBtn");

  return _openBlock(), _createElementBlock("a", {
    href: "",
    class: _normalizeClass(['mainmenu-btn', {
      active: $props.active
    }])
  }, [_createVNode(_component_LpMarkerBtn, {
    tag: "span",
    active: $props.active,
    class: "font-bold"
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["active"])], 2);
}