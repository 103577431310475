import {
  computed,
  reactive,
  ComputedRef,
  UnwrapNestedRefs,
} from 'vue';

import { store } from '@/store';
import { User } from '@/modules/Auth/types';
import { API_GET_USER_DETAILS } from '../store/types';

interface AuthStore {
  user: ComputedRef<User>;
  isLoggedIn: ComputedRef<boolean>;
  isValidUser: ComputedRef<boolean>;
  isTeamMember: ComputedRef<boolean>;
  isPartnerUser: ComputedRef<boolean>;
  getUserDetails: (userId: string) => Promise<User>;
}

const inValidUserTypes = ['partner', 'maintenance'];

export function useAuthService(): UnwrapNestedRefs<AuthStore> {
  const user = computed(() => store.state.auth.user);
  const isLoggedIn = computed(() => store?.getters.isLoggedIn);
  const isValidUser = computed(() => user.value?.type
    && !inValidUserTypes.includes(user.value?.type));
  const isTeamMember = computed(() => (user.value?.type === 'team_member'));
  const isPartnerUser = computed(() => (user.value?.type === 'partner'));

  async function getUserDetails(userId: string) {
    const response = await store.dispatch(
      API_GET_USER_DETAILS.REQUEST,
      {
        params: {
          id: userId,
        },
      },
    );

    if (response.error) {
      throw new Error(response.error);
    }

    return response.data as User;
  }

  return reactive({
    user,
    isLoggedIn,
    isValidUser,
    isTeamMember,
    isPartnerUser,
    getUserDetails,
  });
}
