import { useAuthService } from '@/modules/Auth/services/AuthService';

const AuthService = useAuthService();

const routes = [
  {
    path: '/brands',
    name: 'brands',
    component: () => import(
      /* webpackChunkName: "ClusterModule" */
      '@/modules/Clusters/ClustersModule.vue'
    ),
    meta: {
      mainClasses: 'App-main__withall',
      clusterType: 'Brand',
      hideAside: false,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      requiresActiveProperties: true,
      accountSettingsPage: true,
      requiresValidUser: AuthService.isValidUser,
    },
    children: [
      {
        path: '',
        name: 'brands',
        component: () => import(
          /* webpackChunkName: "ClustersModule" */
          '@/modules/Clusters/views/ClusterList.vue'
        ),
      },
      {
        path: ':id',
        name: 'brand',
        component: () => import(
          /* webpackChunkName: "ClustersModule" */
          '@/modules/Clusters/views/ClusterDetails.vue'
        ),
      },
    ],
  },
  {
    path: '/clusters',
    name: 'clusters',
    component: () => import(
      /* webpackChunkName: "ClustersModule" */
      '@/modules/Clusters/ClustersModule.vue'
    ),
    meta: {
      mainClasses: 'App-main__withall',
      hideAside: false,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      requiresActiveProperties: true,
      accountSettingsPage: true,
      clusterType: 'Cluster',
      requiresValidUser: AuthService.isValidUser,
    },
    children: [
      {
        path: '',
        name: 'clusters',
        component: () => import(
          /* webpackChunkName: "ClustersModule" */
          '@/modules/Clusters/views/ClusterList.vue'
        ),
      },
      {
        path: ':id',
        name: 'cluster',
        component: () => import(
          /* webpackChunkName: "ClustersModule" */
          '@/modules/Clusters/views/ClusterDetails.vue'
        ),
      },
    ],
  },
  {
    path: '/clusters/create',
    name: 'create-cluster',
    component: () => import(
      /* webpackChunkName: "ClustersModule" */
      '@/modules/Clusters/views/CreateCluster/CreateCluster.vue'
    ),
    meta: {
      clusterType: 'Cluster',
      hideAside: true,
      hideHeader: true,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      requiresActiveProperties: true,
      accountSettingsPage: true,
      requiresValidUser: AuthService.isValidUser,
    },
  },
  {
    path: '/brands/create',
    name: 'create-brand',
    component: () => import(
      /* webpackChunkName: "ClustersModule" */
      '@/modules/Clusters/views/CreateCluster/CreateCluster.vue'
    ),
    meta: {
      clusterType: 'Brand',
      hideAside: true,
      hideHeader: true,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      requiresActiveProperties: true,
      accountSettingsPage: true,
      requiresValidUser: AuthService.isValidUser,
    },
  },
];

export default routes;
