import { buildApiRequest } from '@core/api';

import {
  API_GET_PREFERENCES,
  API_PUT_PREFERENCES_UPDATE,
  UPDATE_STATE_USER_PREFERENCES,
} from './types';

import {
  userSettings as apiUserSettings,
  userSettingsUpdate as apiUserSettingsUpdate,
} from '@/api/routes/preferences';

const state = {};
const getters = {};
const mutations = {};
const actions = {
  [UPDATE_STATE_USER_PREFERENCES]: ({ rootState }, userPreferences) => {
    const { user } = rootState.auth;
    rootState.auth.user = {
      ...user,
      ...userPreferences,
    };
  },
};

const preferencesModule = {
  state,
  getters,
  mutations,
  actions,
};

buildApiRequest(
  preferencesModule,
  API_GET_PREFERENCES,
  apiUserSettings,
);

buildApiRequest(
  preferencesModule,
  API_PUT_PREFERENCES_UPDATE,
  apiUserSettingsUpdate,
);

export default preferencesModule;
