import { format } from 'd3-format';

export const formatBooleanYesNo = (value: boolean) => (value ? 'yes' : 'no');
export const percentFormat = '.1~%';
export const numericFormat = ',.1~f';
export const trimNumericFormat = '.3s';
export const numericCurrFormat = '$,.1~f';

export const formatMapping = {
  percentage: percentFormat,
  numeric: numericFormat,
  trim: trimNumericFormat,
  currency: numericCurrFormat,
};

// TODO: Explore the possibility of implementing a generic to ensure the type returned by the function.
export const formatValue = (
  value: string | number | null | undefined,
  dataFormat?: string,
) => {
  if (value && (typeof value === 'string')) {
    return value;
  }

  if (typeof value !== 'number') {
    return '-';
  }

  if (!dataFormat) {
    return value;
  }

  // dont show negative percentages
  const absValue = dataFormat.match(/%/)
    ? Math.abs(value / 100)
    : value;

  const formatedValue = format(dataFormat)(absValue).replace(/−/, '-');

  if (dataFormat === trimNumericFormat) {
    return formatedValue.replace(/G/, 'B');
  }

  return formatedValue;
};
