import { buildApiTypes } from '@core/api';

export const { actionTypes: API_GET_NOTIFICATIONS } = buildApiTypes('notifications/API_GET_NOTIFICATIONS');
export const { actionTypes: API_POST_NOTIFICATION } = buildApiTypes('notifications/API_POST_NOTIFICATION');
export const { actionTypes: API_PUT_NOTIFICATION } = buildApiTypes('notifications/API_PUT_NOTIFICATION');
export const { actionTypes: API_DELETE_NOTIFICATION } = buildApiTypes('notifications/API_DELETE_NOTIFICATION');
export const { actionTypes: API_PUT_STATUS_NOTIFICATION } = buildApiTypes('notifications/API_PUT_STATUS_NOTIFICATION');

export const getterNames = {
  NOTIFICATIONS_GET_NOTIFICATIONS: 'notifications/GET_NOTIFICATIONS',
  NOTIFICATIONS_GET_NOTIFICATIONS_FETCHING: 'notifications/GET_NOTIFICATIONS_FETCHING',
  NOTIFICATIONS_POST_NOTIFICATION_FETCHING: 'notifications/POST_NOTIFICATION_FETCHING',
  NOTIFICATIONS_PUT_NOTIFICATION_FETCHING: 'notifications/PUT_NOTIFICATION_FETCHING',
  NOTIFICATIONS_DELETE_NOTIFICATION_FETCHING: 'notifications/DELETE_NOTIFICATION_FETCHING',
};
