import { unref, computed } from 'vue';
import { get } from 'lodash';

import { isPlainObject } from '@/utils';

export const useSearchList = (list, searchKeys) => {
  const searchList = (searchString) => {
    const search = (obj, key) => {
      const targetItem = get(obj, key);
      if (isPlainObject(targetItem)) {
        return Object.keys(targetItem).some(iKey => search(targetItem, iKey));
      }
      return String(targetItem).toLowerCase().includes(unref(searchString).toLowerCase());
    };

    const result = computed(() => {
      const allKeysOfList = unref(list)[0] ? Object.keys(unref(list)[0]) : [];
      const pSearchKeys = !searchKeys ? allKeysOfList : searchKeys;
      return unref(list).filter(
        (obj) => pSearchKeys.some(key => search(obj, key)),
      );
    });

    return result;
  };

  return searchList;
};
