import { useAuthService } from '@/modules/Auth/services/AuthService';

const AuthService = useAuthService();

const routes = [
  {
    path: '/settings',
    name: 'settings',
    component: () => import(
      /* webpackChunkName: "Settings" */
      '@/modules/Settings/SettingsModule.vue'
    ),
    meta: {
      mainClasses: 'App-main__withall',
      hideAside: false,
      hideFooter: true,
      requiresAuth: true,
      requiresAccountSelected: true,
      accountSettingsPage: true,
      requiresValidUser: AuthService.isValidUser,
    },
  },
];

export default routes;
