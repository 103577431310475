export const userExists = '/user-history/user/exists';
export const userUpdateProfile = '/user/user/:id';
export const userChangePassword = '/user/user/:id/set-new-password';
export const userResetPassword = '/user/user/change-password-hash';
export const userValidateHash = '/user-history/validate-reset-password-hash';
export const userValidateHashFromInvite = '/user-history/user/find-by-hash';
export const userDetails = '/user-history/user/:id';
export const partnerDetails = '/user-history/partner/:email';
export const userApprove = '/user/user/approve';

export const helpCenter = '/auth/hc';
